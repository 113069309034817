<template>
    <v-container fill-height>
        <v-layout
            justify-center
            align-center
        >
            <v-flex
                xs12
                sm6
                md4
                lg3
                xl3
            >
                <v-card
                    class="elevation-12"
                >
                    <v-toolbar
                        color="#018656"
                        dark
                    >
                        <v-toolbar-title>Login</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-progress-circular
                            v-show="carregando"
                            indeterminate
                            color="white"
                            width="2"
                        ></v-progress-circular>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                v-on:keyup.enter="login"
                                prepend-icon="fas fa-building"
                                name="cod_empresa"
                                label="Empresa"
                                type="number"
                                autocomplete="off"
                                v-model.trim="usuario.cod_empresa"
                                color="#018656"
                            ></v-text-field>
                            <v-text-field
                                v-on:keyup.enter="login"
                                prepend-icon="fas fa-envelope"
                                name="email"
                                label="Email"
                                type="email"
                                autocomplete="off"
                                v-model.trim="usuario.email"
                                color="#018656"
                            ></v-text-field>
                            <v-text-field
                                v-on:keyup.enter="login"
                                prepend-icon="fas fa-lock"
                                name="senha"
                                label="Senha"
                                type="password"
                                v-model.trim="usuario.senha"
                                color="#018656"
                            ></v-text-field>
                        </v-form>
                        <v-btn
                            block
                            depressed
                            color="#018656"
                            class="mt-4 btnEntrar"
                            @click="login"
                        >
                            <v-icon
                                small
                                class="mr-2"
                            >fas fa-check</v-icon>
                            Entrar
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { baseApiUrl, baseApiUrlPersys, showSuccess, showError, userKey } from '@/global'
import axios from 'axios'

export default {
    name: 'Login',
    data: () => ({
        carregando: false,
        usuario: {
            cod_empresa: '',
            email: '',
            senha: '',
        },
    }),
    methods: {
        login () {
            this.carregando = true
            axios.post(`${baseApiUrl}/sessoes`, this.usuario)
            .then(response => {
                this.carregando = false
                localStorage.setItem(userKey, response.data.token)
                localStorage.setItem("factory", this.usuario.cod_empresa)
                localStorage.setItem("actRead", response.data.subjectiveReading)
                this.$store.state.cod_empresa = this.usuario.cod_empresa;
                this.$store.state.token_sisfrete = response.data.token;

                axios.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.token_sisfrete}`

                let url = `${baseApiUrl}/empresas?cod_empresa=${this.usuario.cod_empresa}`
                axios.get(url)
                    .then(res => {
                        localStorage.setItem("name",res.data.empresas.data[0].razao_social)
                        this.$store.state.name = res.data.empresas.data[0].razao_social;
                    })
                let urlToken = `${baseApiUrlPersys}/chaves/${this.usuario.cod_empresa}`
                axios.get(urlToken)
                    .then(res => {
                        localStorage.setItem("Token-API", res.data.tokenApi)
                        this.$store.state.token_persys = res.data.tokenApi;
                        this.$store.state.integracao = res.data.integracao;
                    })
                showSuccess(response.data.mensagem)
                this.$router.push('/')
            })
            .catch(error => {
                this.carregando = false
console.log("error", error);
                showError(error.response.data.mensagem)
            })
        }
    }
}
</script>

<style scoped>
    .btnEntrar {
        color: #fff !important;
    }
</style>
